<template>
  <div class="seat-details">
    <span class="fal fa-chair pr-1" />
    <span class="seat-details__text">
      {{selectedContractName}} •
      starting {{formattedDate(availableAt)}} •
      ${{cost/100}}/{{costFrequency}}
    </span>
  </div>
</template>

<script>
export default {
  name: 'seat-details',
  props: {
    selectedContractName: {
      type: String,
      required: true,
    },
    availableAt: {
      type: String,
      required: true,
    },
    cost: {
      type: Number,
      required: true,
    },
    costFrequency: {
      type: String,
      required: true,
    },
  },
  methods: {
    formattedDate(dateString) {
      return this.$moment(dateString).utc().format('MMMM D, YYYY');
    },
  },
};
</script>

<style scoped>
.seat-details {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: var(--grid-unit);
}

.seat-details__text {
  line-height: 1.2;
}
</style>
