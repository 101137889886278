import { axios, getEnvVar, initSentry } from '@utils';
import Vue from 'vue';
import { sync } from 'vuex-router-sync';
import ActionCableVue from 'actioncable-vue';
import VueMoment from 'vue-moment';
import moment from 'moment-timezone';
import VueSweetalert2 from 'vue-sweetalert2';
import FloatingVue from 'floating-vue';
import Navigation from '@parent/components/navigation/navigation';
import CareProviderNavigation from '@care-provider/components/care-provider-navigation.vue';
import VueObserveVisibility from 'vue-observe-visibility';
import Notification from '@components/notification';
import ToastContainer from '@components/toast-notifications/toast-container.vue';
import router from '@routes/parents.router';
import * as VueGoogleMaps from 'vue2-google-maps';
import store from '@parent/stores/parent.store';
import Cloudinarize from '@plugins/cloudinarize';
import SyncRouteStore, { parentConfig } from '@plugins/syncRouteStore';
import UserAccessPlugin from '@plugins/userAccess';
import PortalVue from 'portal-vue';
import ModalTarget from '@components/modal/modal-target.vue';
import ProfileMap from '@profile/components/map.vue';
import ProfileCta from '@profile/components/cta.vue';
import ProfileOpinionBar from '@profile/components/opinion-bar.vue';
import ProfileButtonReviews from '@profile/components/button-reviews.vue';
import ProfileGallery from '@profile/components/gallery.vue';
import ProfileOffers from '@profile/components/offers.vue';
import ProfileBookTour from '@profile/components/book-tour.vue';
import ProfileWaitlistMobile from '@profile/components/waitlist-mobile.vue';
import ProfileSpotOffer from '@profile/components/spot-offer.vue';
import ExpandableSeatList from '@profile/components/expandable-seat-list.vue';
import AuthMixin from '@profile/mixins/auth';
import payMeFlow from '@parent/components/payments/send-payment/pay-me-flow.vue';
import PaymentRequestSummary from '@parent/components/payments/request-payment/payment-request-summary.vue';

Vue.use(PortalVue);
Vue.use(VueMoment, { moment });
Vue.use(VueSweetalert2);
Vue.use(VueObserveVisibility);
Vue.use(SyncRouteStore, parentConfig);
Vue.use(UserAccessPlugin);
Vue.use(Cloudinarize);
Vue.use(FloatingVue, {
  themes: {
    'kinside-info': {
      $extend: 'tooltip',
      triggers: ['hover', 'touch'],
      autoHide: false,
      placement: 'top',
    },
  },
});

Vue.use(ActionCableVue, {
  debug: getEnvVar('NODE_ENV') === 'development',
  debugLevel: 'error',
  connectionUrl: getEnvVar('WEBSOCKET_URL'),
  connectImmediately: true,
  store,
});

Vue.use(VueGoogleMaps, {
  load: {
    key: getEnvVar('GOOGLE_API_KEY'),
    libraries: 'places',
  },
});

document.addEventListener('DOMContentLoaded', () => {
  initSentry();

  const unsync = sync(store, router);
  // eslint-disable-next-line no-unused-vars
  const parentsApp = new Vue({
    router,
    store,

    mixins: [
      AuthMixin,
    ],

    created() {
      this.onWindowResize();
      window.addEventListener('resize', this.onWindowResize);
      this.$sync.setup(this.$router, this.$store);
    },

    async mounted() {
      axios.defaults.headers.common.Accepts = '*/*';

      const metaCsrf = document.querySelector('meta[name="csrf-token"]');
      if (metaCsrf) {
        axios.defaults.headers.common['X-CSRF-Token'] = metaCsrf.getAttribute('content');
      }

      if (this.isAuthorized) {
        await this.$store.dispatch('init');
        this.$cable.subscribe({
          channel: 'ConversationsChannel',
          type: 'User',
          id: this.$store.state.user.current.id,
        });
      }

      // style scrollbars when visible
      const isScrollbarHidden = window.innerWidth === document.body.clientWidth;
      if (!isScrollbarHidden) {
        document.body.classList.add('styled-scrollbar');
      }
    },

    beforeDestroy() {
      unsync();
      window.removeEventListener('resize', this.onWindowResize);
    },

    components: {
      Navigation,
      CareProviderNavigation,
      Notification,
      ToastContainer,
      ModalTarget,
      ProfileMap,
      ProfileCta,
      ProfileOpinionBar,
      ProfileButtonReviews,
      ProfileGallery,
      ProfileOffers,
      ProfileBookTour,
      ProfileWaitlistMobile,
      ProfileSpotOffer,
      payMeFlow,
      ExpandableSeatList,
      PaymentRequestSummary,
    },

    data() {
      return {
        showProfile: true,
      };
    },

    watch: {
      $route(to) {
        this.showProfile = to.name === 'facility-profile';
      },
    },

    methods: {
      reload() {
        window.location.reload();
      },
      onWindowResize() {
        this.$store.commit('setIsMobile', window.innerWidth < 768);
      },
      disconnect() {
        this.$cable.connection.disconnect();
      },
    },

    channels: {
      MessagesChannel: {
        received(message) {
          this.$store.dispatch('messaging/newMessageReceived', message);
        },
      },
      ConversationsChannel: {
        received(conversation) {
          this.$store.dispatch('messaging/newConversationReceived', conversation);
        },
      },
    },
  }).$mount('#parents');

  if (window.Cypress) {
    // only available during E2E tests
    window.app = parentsApp;
  }
});
