<template>
  <div>
    <template v-if="!isLegupTourBooked && tourEnabled && this.provider.currFacility && showContent">
      <system-button
        color="periwinkle"
        @click="tourModal(true)">
        Book a tour
      </system-button>

      <legup-tour
        :facilityName="this.currFacility.name"
        :facilityTimezone="this.currFacility.timezone"
        :facilityPrograms="this.currFacility.programs"
        :legupCenterId="this.currFacility.legupCenterId"
        :isOpen="tourOpen"
        :edit="editTour"
        :seatId="seatId"
        :bookedTour="upcomingTour"
        :closeModal="() => tourModal(false)"
        @tour-booked="updateTour"
        @tour-cancelled="onTourCancelled"
        @close-tour-modal="tourModal(false)"
      />
    </template>
    <template v-else>
      <router-link
        :to="{ name: 'messaging', query: {
          facilityId: this.currFacility ? this.currFacility.id : null,
          providerId: this.currFacility ? this.currFacility.providerId : null,
          facilityName: this.currFacility ? this.currFacility.name : null }
        }"
      >
        <system-button
          color="periwinkle"
          >
          Message provider
        </system-button>
      </router-link>
    </template>
  </div>
</template>

<script>
import SystemButton from '@components/buttons/system-button.vue';
import tourMixin from '@profile/mixins/tour';
import profileComponentMixin from '@profile/mixins/profile-component';

import LegupTour from '@components/facility-profile/legup-tour.vue';

export default {
  name: 'profile-book-tour',

  mixins: [
    tourMixin,
    profileComponentMixin,
  ],

  components: {
    SystemButton,
    LegupTour,
  },

  props: {
    tourEnabled: {
      type: Boolean,
      default: true,
    },
    seatId: {
      type: Number,
    },
  },
  data() {
    return {
      tourOpen: false,
    };
  },
  computed: {
    currFacility() {
      return this.provider.currFacility;
    },
  },
};
</script>

<style lang="scss" scoped>
.offers {
  margin-bottom: calc(var(--grid-unit) * 6);
}
</style>
